.sp-loto { display: flex; }

@mixin custom-col($divider) {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%/$divider;
  -ms-flex: 0 0 100%/$divider;
  flex: 0 0 100%/$divider;
  max-width: 100%/$divider;
  &:not(:nth-child(n+#{$divider + 1})) { margin-top: 0; }
  &:nth-child(n+#{$divider + 1}) { margin-top: .5rem; }
}

.sp-lotogame-buttons {

  @include custom-col(4);
  @media (min-width: 992px){ @include custom-col(6); }
  // @media (min-width: 1200px){ @include custom-col(8); }
}

.sp-loto-slip { float: none; }



.sp-toggle[toggle=false] { 

  @media (max-width: 1200px){
    display: none;
  }
}

.sp-dflex {
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 1200px){ flex-direction: row; }
}